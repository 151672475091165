import { AssetConfigContext } from 'contexts/assetConfig.context';
import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';
import {
  getArrayOfValuesFromAssetStatusOptions,
  getArrayOfValuesFromOptions,
} from '../utils/utils';
import { AssetStatesContext } from 'contexts/assetStates.context';
export type searchTypeType = 'assets' | 'users' | 'none';

type SearchFilterTypes = {
  doNotCalculateFloor: boolean;
  setDoNotCalculateFloor: (arg: boolean) => void;
  inSearchMode: boolean;
  setInSearchMode: (arg: boolean) => void;
  inFilterMode: boolean;
  setInFilterMode: (arg: boolean) => void;
  searchAPIcallInProgress: boolean;
  setSearchAPIcallInProgress: (arg: boolean) => void;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  searchType: searchTypeType;
  setSearchType: (arg: searchTypeType) => void;
  assetOwnerCheckedListArray: [] | string[];
  setAssetOwnerCheckedListArray: (value: string[] | []) => void;
  assetSubtypeCheckedListArray: [] | string[];
  setAssetSubtypeCheckedListArray: (value: string[] | []) => void;
  assetStatusCheckedListArray: [] | string[];
  setAssetStatusCheckedListArray: (value: string[] | []) => void;
  assetStatusFilterModified: boolean;
  setAssetStatusFilterModified: (value: boolean) => void;
  setAssetSubtypeCheckAll: (arg: boolean) => void;
  setAssetOwnerCheckAll: (arg: boolean) => void;
  assetSubtypeCheckAll: boolean;
  assetOwnerCheckAll: boolean;
  defaultAssetStatusCheckedListArray: [] | string[];
  assetStatusCheckAll: boolean;
  setAssetStatusCheckAll: (arg: boolean) => void;
  hasSearchTerm: boolean;
  menuIsOpen: boolean;
  setMenuIsOpen: (arg: boolean) => void;
  assetOwnerFilterModified: boolean;
  setAssetOwnerFilterModified: (arg: boolean) => void;
  assetSubtypeFilterModified: boolean;
  setAssetSubtypeFilterModified: (arg: boolean) => void;
  filtersModified: boolean;
  setFiltersModified: (arg: boolean) => void;
  defaultAssetOwnerCheckedListArray: string[];
  defaultAssetSubtypeCheckedListArray: string[];
  showAssetsPeopleSelectedCheckboxArrayCachedRef: any;
  cachedEstimateRef: any;
};

export const SearchFilterContext = createContext({} as SearchFilterTypes);

type SearchFilterProviderProps = { children: ReactNode };

// A context that stores search and filter params for them to be used by polling components.
const SearchFilterProvider = ({ children }: SearchFilterProviderProps) => {
  const { assetOwners, assetSubtype } = useContext(AssetConfigContext);
  const { availableAssetStates } = useContext(AssetStatesContext);

  const [searchType, setSearchType] = useState<searchTypeType>('none');

  //booleans
  const [doNotCalculateFloor, setDoNotCalculateFloor] = useState(false);
  const [inSearchMode, setInSearchMode] = useState(false);
  const [inFilterMode, setInFilterMode] = useState(false);
  const [searchAPIcallInProgress, setSearchAPIcallInProgress] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filtersModified, setFiltersModified] = useState(false);

  const [assetOwnerCheckedListArray, setAssetOwnerCheckedListArray] = useState<string[] | []>([]);
  const [assetOwnerFilterModified, setAssetOwnerFilterModified] = useState(false);
  const [assetSubtypeCheckedListArray, setAssetSubtypeCheckedListArray] = useState<string[] | []>(
    [],
  );
  const [assetSubtypeFilterModified, setAssetSubtypeFilterModified] = useState(false);
  const [assetStatusCheckedListArray, setAssetStatusCheckedListArray] = useState<string[] | []>([]);
  const [assetStatusFilterModified, setAssetStatusFilterModified] = useState(false);

  const defaultAssetOwnerCheckedListArray = getArrayOfValuesFromOptions(assetOwners);
  const defaultAssetSubtypeCheckedListArray = getArrayOfValuesFromOptions(assetSubtype);
  const defaultAssetStatusCheckedListArray =
    getArrayOfValuesFromAssetStatusOptions(availableAssetStates);

  const [assetStatusCheckAll, setAssetStatusCheckAll] = useState(true);
  const [assetSubtypeCheckAll, setAssetSubtypeCheckAll] = useState(true);
  const [assetOwnerCheckAll, setAssetOwnerCheckAll] = useState(true);
  const showAssetsPeopleSelectedCheckboxArrayCachedRef = useRef(null);

  const cachedEstimateRef = useRef(null); // for comparing single assets returned from search results.

  const hasSearchTerm = searchTerm.length > 0;
  const value: SearchFilterTypes = {
    searchType,
    setSearchType,
    doNotCalculateFloor,
    setDoNotCalculateFloor,
    inSearchMode,
    setInSearchMode,
    inFilterMode,
    setInFilterMode,
    searchAPIcallInProgress,
    setSearchAPIcallInProgress,
    searchTerm,
    setSearchTerm,
    assetOwnerCheckedListArray,
    setAssetOwnerCheckedListArray,
    assetSubtypeCheckedListArray,
    setAssetSubtypeCheckedListArray,
    assetStatusCheckedListArray,
    setAssetStatusCheckedListArray,
    assetStatusFilterModified,
    setAssetStatusFilterModified,
    assetSubtypeCheckAll,
    setAssetSubtypeCheckAll,
    assetOwnerCheckAll,
    setAssetOwnerCheckAll,
    defaultAssetStatusCheckedListArray,
    assetStatusCheckAll,
    setAssetStatusCheckAll,
    hasSearchTerm,
    menuIsOpen,
    setMenuIsOpen,
    filtersModified,
    setFiltersModified,
    assetOwnerFilterModified,
    setAssetOwnerFilterModified,
    assetSubtypeFilterModified,
    setAssetSubtypeFilterModified,
    defaultAssetOwnerCheckedListArray,
    defaultAssetSubtypeCheckedListArray,
    showAssetsPeopleSelectedCheckboxArrayCachedRef,
    cachedEstimateRef,
  };

  return <SearchFilterContext.Provider value={value}>{children}</SearchFilterContext.Provider>;
};

export default SearchFilterProvider;
