import {
  getCoordsFromAssetUserEstimates,
  getLevelFeatureDataArrayFromBuildingID,
  getOutdoorEstimates,
} from '../utils/utils';
import L from 'leaflet';
import { latLngBounds } from 'leaflet';
import { useContext } from 'react';
import { MapContainerRefContext } from '../contexts/mapContainerRef.context';
import { UserType } from 'types/userInfo';
import { EstimateType } from 'types/Estimate';

export default function useMapHooksExternalMapRef() {
  // useEffect that uses leaflets map methods on mapstate passed in from mapContainerRef context.
  // functions are called by components and hooks that exist outside of mapcontainer eg search.
  const { mapContainerRef } = useContext(MapContainerRefContext);

  function fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors(
    levelsFeaturesData: any,
    estimates: EstimateType[] | UserType[],
    currentSelectedBuildingID?: string,
  ): void {
    // function that pans map to bounds of all building levels which contain assets.
    // and outdoor assets.
    // used for panning map after searching assets within multiple buildings.
    if (!mapContainerRef) return;

    let buildingIDArray: string[] = [];
    const bounds = latLngBounds([]); // start with empty bounds
    const outdoorEstimates = getOutdoorEstimates(estimates);
    const outdoorAssetCoords: any = getCoordsFromAssetUserEstimates(outdoorEstimates);
    // first extend bounds to all outdoor asset coords.
    bounds.extend(outdoorAssetCoords);

    // get all unique building IDs from non outdoor Assets
    estimates.forEach((estimate) => {
      if (!estimate.location.is_outdoors) {
        estimate.location.building_level.possible_buildings.forEach((building) => {
          if (!buildingIDArray.includes(building.id)) {
            buildingIDArray.push(building.id);
          }
        });
      }
    });

    // add current selected building if it is passed in
    if (currentSelectedBuildingID) {
      if (!buildingIDArray.includes(currentSelectedBuildingID)) {
        buildingIDArray.push(currentSelectedBuildingID);
      }
    }

    // loop through all buildingIDs and get levels features data that match assets building ID
    buildingIDArray.forEach((buildingID) => {
      const buildingIDLevelsData = getLevelFeatureDataArrayFromBuildingID(
        buildingID,
        levelsFeaturesData,
      );
      const geoJSONLayer = L.geoJSON(buildingIDLevelsData);
      // expand bounds to each buildings data.
      bounds.extend(geoJSONLayer.getBounds());
    });
    // finally pan to fully extended bounds.
    mapContainerRef.fitBounds(bounds);
  }

  function panMapToProvidedCombinedLevelsAssetUserBounds(
    combinedEstimateBounds: EstimateType[] | UserType[],
    levelsFeatures: any,
  ) {
    if (!mapContainerRef) return;
    const bounds = L.geoJSON(levelsFeatures).getBounds();
    const estimateBounds = getCoordsFromAssetUserEstimates(combinedEstimateBounds);

    bounds.extend(estimateBounds);
    mapContainerRef.fitBounds(bounds, { animate: true });
  }

  function panMapToCombinedAssetBounds(combinedAssetBounds: EstimateType[] | UserType[]) {
    if (!mapContainerRef) return;

    const bounds = latLngBounds([]); // start with empty bounds
    const assetCoords: any = getCoordsFromAssetUserEstimates(combinedAssetBounds);

    bounds.extend(assetCoords);
    mapContainerRef.fitBounds(bounds, { animate: true, maxZoom: 19 });
  }

  function fitMapToBoundsOfBuildingLevelsFromBuildingID(
    buildingID: string,
    levelsFeaturesData: any,
  ) {
    // function to get levels feature data from buildingID, and pan map to all included levels containing building ID.
    if (!mapContainerRef) return;

    const bounds = latLngBounds([]); // start with empty bounds
    const buildingIDLevelsData = getLevelFeatureDataArrayFromBuildingID(
      buildingID,
      levelsFeaturesData,
    );
    const geoJSONLayer = L.geoJSON(buildingIDLevelsData);
    // expand bounds to each buildings data.
    bounds.extend(geoJSONLayer.getBounds());
    // finally pan to fully extended bounds.
    mapContainerRef.fitBounds(bounds);
  }

  function panMapToCombinedAssetsInMultipleBuildings(
    indoorEstimates: EstimateType[] | UserType[],
    outdoorEstimates: EstimateType[] | UserType[],
    levelsFeaturesData: any,
  ) {
    if (!mapContainerRef) return;

    const bounds = latLngBounds([]); // start with empty bounds
    // first extend bounds by outdoor assets
    const outdoorAssetCoords: any = getCoordsFromAssetUserEstimates(outdoorEstimates);
    bounds.extend(outdoorAssetCoords);

    indoorEstimates.forEach((indoorEstimate: EstimateType | UserType) => {
      // then loop through all indoor estimates, retrieve building ID,
      // then obtain corresponding levels data for building
      const buildingID = indoorEstimate.location.building_level.possible_buildings[0].id;

      const buildingIDLevelsData = getLevelFeatureDataArrayFromBuildingID(
        buildingID,
        levelsFeaturesData,
      );
      const geoJSONLayer = L.geoJSON(buildingIDLevelsData);
      // expand bounds to each buildings data.
      bounds.extend(geoJSONLayer.getBounds());
    });

    mapContainerRef.fitBounds(bounds, { animate: true, maxZoom: 19 });
  }
  function panMapToBuildingLevelsAndEstimates(
    outdoorEstimates: EstimateType[] | UserType[],
    levelsFeaturesData: any,
  ) {
    if (!mapContainerRef) return;

    const bounds = latLngBounds([]); // start with empty bounds

    const outdoorAssetCoords: any = getCoordsFromAssetUserEstimates(outdoorEstimates);
    const geoJSONLayer = L.geoJSON(levelsFeaturesData);

    bounds.extend(outdoorAssetCoords);
    bounds.extend(geoJSONLayer.getBounds());
    mapContainerRef.fitBounds(bounds);
  }
  return {
    panMapToCombinedAssetBounds,
    fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors,
    panMapToProvidedCombinedLevelsAssetUserBounds,
    fitMapToBoundsOfBuildingLevelsFromBuildingID,
    panMapToCombinedAssetsInMultipleBuildings,
    panMapToBuildingLevelsAndEstimates,
  };
}
