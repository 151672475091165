import React, { useContext } from 'react';
import { StyledMobileSearchFilterButton, StyledMobileSearchFilterDiv } from './mobileStyles';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import clearIcon from '../../icons/clearIcon.svg';

export default function MobileSelectFiltersButton({ onClick }: { onClick: () => void }) {
  const { setRecallGetAssets, setRecallGetUsers } = useContext(MapControlContext);
  const {
    inSearchMode,
    setSearchTerm,
    inFilterMode,
    setInFilterMode,
    setInSearchMode,
    setDoNotCalculateFloor,
    setAssetOwnerCheckAll,
    setAssetOwnerCheckedListArray,
    setAssetSubtypeCheckAll,
    setAssetSubtypeCheckedListArray,
    defaultAssetOwnerCheckedListArray,
    defaultAssetSubtypeCheckedListArray,
  } = useContext(SearchFilterContext);

  const shouldShowClearButton = inFilterMode || inSearchMode;

  function handleClearSearchFilters() {
    setInSearchMode(false);
    setInFilterMode(false);
    setRecallGetAssets(true);
    setRecallGetUsers(true);
    setSearchTerm('');
    setDoNotCalculateFloor(true);
    setAssetOwnerCheckAll(true);
    setAssetOwnerCheckedListArray(defaultAssetOwnerCheckedListArray);
    setAssetSubtypeCheckAll(true);
    setAssetSubtypeCheckedListArray(defaultAssetSubtypeCheckedListArray);
  }

  return (
    <StyledMobileSearchFilterDiv>
      <StyledMobileSearchFilterButton onClick={() => onClick()} data-test-id="search-filter-button">
        Search & Filter
      </StyledMobileSearchFilterButton>
      {shouldShowClearButton && (
        <StyledMobileSearchFilterButton className="--clear" onClick={handleClearSearchFilters}>
          Clear {inSearchMode ? 'Search' : 'Filter'} <img src={clearIcon} alt="clear" />
        </StyledMobileSearchFilterButton>
      )}
    </StyledMobileSearchFilterDiv>
  );
}
