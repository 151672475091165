import { EstimateType } from 'types/Estimate';

import { UserType } from 'types/userInfo';
import { VenueListType } from 'types/venue';
import { ReactSelectType } from '../components/VenueSelectDropdownControl';
import L from 'leaflet';
import { AssetStatesObjectType } from 'types/AssetStatus';

export function filterAssetMarkersByFloorUUID(
  assetMarkers: EstimateType[],
  floorSelectedUUIDArray: string[],
) {
  // function  returns array of markers whos building_level_uuid matches one of the selected floor UUIDs
  return assetMarkers.filter((asset: EstimateType) => {
    return floorSelectedUUIDArray.includes(asset.location.building_level.level.id);
  });
}

export function filterUsersMarkersByFloorUUID(
  usersMarkers: UserType[],
  floorSelectedUUIDArray: string[],
) {
  // function returns array of markers whos location.building_level.level.id matches one of the selected floor UUIDs

  return usersMarkers.filter((user: UserType) => {
    return floorSelectedUUIDArray.includes(user.location.building_level.level.id);
  });
}

export function checkEstimateIsOnSelectedFloor(
  estimate: EstimateType | UserType,
  floorSelectedIDArray: string[],
): boolean {
  // function that checks if selected floorIDs contain estimates building level_uuid and returns boolean

  return floorSelectedIDArray.includes(estimate.location.building_level.level.id);
}

export function getUnitFeatureByFloorSelectedUUID(unitFeatures: any, floorSelectedUUID: string) {
  return unitFeatures.filter((feature: any) => feature.properties.level_id === floorSelectedUUID);
}

export function getLevelFeatureDataArrayFromBuildingID(
  buildingID: string,
  levelFeatures: any,
): any {
  const levelsFeaturesWithMatchingBuildingIDsArray = levelFeatures.filter((feature: any) => {
    if (feature.properties.building_ids) {
      return feature.properties.building_ids.includes(buildingID);
    }
  });

  return levelsFeaturesWithMatchingBuildingIDsArray;
}

export function getLevelFeatureDataFromOrdinal(ordinal: number, levelFeatures: any) {
  // a function to return level feature data from relevant level ordinal.
  return levelFeatures.filter(
    (levelFeature: any) => levelFeature.properties.ordinal === ordinal,
  )[0];
}

export function getUnitFeatureDataFromLevelIDs(levelIDArray: string[], unitFeatures: any): any {
  // return unit feature data filtered by each id in levelIDArray.
  let combinedUnitFeatures: any = [];

  levelIDArray.forEach((levelID) => {
    const levelIDFilteredFeatures = unitFeatures.filter((feature: any) => {
      return feature.properties.level_id === levelID;
    });
    combinedUnitFeatures = [...combinedUnitFeatures, ...levelIDFilteredFeatures];
  });

  return combinedUnitFeatures;
}

export function getLevelFeaturesWithUniqueOrdinals(associatedBuildingLevels: any) {
  // returns array of features with unique ordinals, so we can use them to display floors.
  return associatedBuildingLevels.filter((value: any, index: number, self: any) => {
    return self.findIndex((v: any) => v.properties.ordinal === value.properties.ordinal) === index;
  });
}

export function getLevelFeaturesSortedByOrdinal(levelFeatures: any) {
  return levelFeatures.sort(
    (a: any, b: any) => parseFloat(a.properties.ordinal) - parseFloat(b.properties.ordinal),
  );
}

export function getAssociatedLevelIDsArrayFromOrdinal(ordinal: number, levelFeatures: any) {
  let array: any[] = [];

  levelFeatures.forEach((feature: any) => {
    if (feature.properties.ordinal === ordinal) {
      array.push(feature.id);
    }
  });
  return array;
}

export function getIndoorUsersWithEstimate(users: UserType[]) {
  // ensures users have estimate, building_level & co-ordinates, to prevent null estimates being passed to map.
  return users.filter((user) => {
    return (
      user !== null &&
      user.location.coordinates &&
      user.location.building_level &&
      !user.location.is_outdoors
    );
  });
}

export function getAssetsWithBuildingAndEstimate(items: EstimateType[]) {
  return items.filter((estimate) => {
    // ensures asset markers shown on map have an assigned beacon,,
    // and that estimate has building data. Otherwise application will crash.
    // we also do not want to include outdoor estimates.

    return (
      estimate.assigned_beacon !== null &&
      estimate.location.building_level !== null &&
      estimate.location.building_level !== undefined &&
      estimate.location.building_level.possible_buildings.length > 0 &&
      !estimate.location.is_outdoors
    );
  });
}

export function getOutdoorEstimates(items: EstimateType[] | UserType[]) {
  // returns all estimates with outdoor boolean.
  let filteredItems: any = [];
  items.forEach((item) => {
    // asset estimate
    if ('assigned_beacon' in item && item.assigned_beacon !== null) {
      if (item.location.is_outdoors) filteredItems.push(item);
    }

    // user estimates
    if ('email' in item && item.location.is_outdoors) {
      if (item.location.is_outdoors) filteredItems.push(item);
    }
  });

  return filteredItems;
}

export function getOutoorUserEstimates(items: UserType[]) {
  // returns all estimates with outdoor boolean.
  return items.filter((item) => item.location.is_outdoors);
}

export function getLowestOrdinalThatHasAsset(
  sortedLevelFeatures: any,
  assetMarkers: EstimateType[],
  selectedFloorOrdinal: number,
) {
  // loop through levels in order from lowest ordinal, and then loop through assets for each level.
  // if level ID matches asset building level id, return ordinal of this level.
  // this is the lowest ORDINAL with an asset.
  let lowestOrdinal = selectedFloorOrdinal; // keep at selected floor for returning null cases.
  let ordinalFound = false;

  sortedLevelFeatures.forEach((levelFeature: any) => {
    if (ordinalFound) return;

    assetMarkers.forEach((asset: EstimateType) => {
      if (ordinalFound) return;
      const assetLevelID = asset.location.building_level.level.id;

      if (levelFeature.id === assetLevelID) {
        lowestOrdinal = levelFeature.properties.ordinal;
        ordinalFound = true;
        return;
      }
    });
  });

  return lowestOrdinal;
}

export function getOrdinalFromLevelID(levelFeatures: any, levelID: string): number {
  const matchedLevelFeature = levelFeatures.filter(
    (levelFeature: any) => levelFeature.id === levelID,
  )[0];

  return matchedLevelFeature?.properties.ordinal;
}

export function getLowestOrdinalThatHasUser(
  sortedLevelFeatures: any,
  usersMarkers: UserType[],
  selectedFloorOrdinal: number,
) {
  // loop through levels in order from lowest ordinal, and then loop through users for each level.
  // if level ID matches asset building level id, return ordinal of this level.
  // this is the lowest ORDINAL with a user.
  let lowestOrdinal = selectedFloorOrdinal; // keep at selected floor for returning null cases.
  let ordinalFound = false;

  sortedLevelFeatures.forEach((levelFeature: any) => {
    if (ordinalFound) return;

    usersMarkers.forEach((user: UserType) => {
      if (ordinalFound) return;

      const userLevelID = user.location.building_level.level.id;

      if (levelFeature.id === userLevelID) {
        lowestOrdinal = levelFeature.properties.ordinal;
        ordinalFound = true;
        return;
      }
    });
  });

  return lowestOrdinal;
}

export function getIndexOfLevelsFromMatchingFloorSelectedIDs(
  sortedLevelsWithUniqueOrdinals: any,
  floorSelectedIDArray: string[],
): number {
  // function to return index of levels array that matches the floorSelectedIDArrays.
  const matchingLevel = sortedLevelsWithUniqueOrdinals.findIndex((level: any) => {
    return floorSelectedIDArray.includes(level.id);
  });

  return matchingLevel;
}

export function getFloorAssetCountFromLevelIDArray(
  levelIDArray: any,
  assetMarkers: EstimateType[],
) {
  // a function that creates an array of markers whos building level uuid matches one of the level_ids in the array.
  // we use this to determine how many assets are on that floor,
  // then return the length of the new array which is the count.
  let filteredMarkersArray = [];

  levelIDArray.forEach((levelID: string) => {
    filteredMarkersArray = assetMarkers.filter((asset) => {
      const { id } = asset.location.building_level.level;
      return levelID === id;
    });
  });

  return filteredMarkersArray.length;
}

export function getFloorUserCountFromLevelIDArray(levelIDArray: string[], userMarkers: UserType[]) {
  // a function that creates an array of markers whos building level uuid matches one of the level_ids in the array.
  // we use this to determine how many users are on that floor,
  // then return the length of the new array which is the count.
  let filteredMarkersArray = [];

  levelIDArray.forEach((levelID: string) => {
    filteredMarkersArray = userMarkers.filter((user) => {
      const buildingLevelID = user.location.building_level.level.id;

      return levelID === buildingLevelID;
    });
  });

  return filteredMarkersArray.length;
}

export function getBuildingAssetCountFromFeatureBuildingUUIDArray(
  buildingUUIDArray: string[],
  assetMarkers: EstimateType[],
) {
  let filteredMarkersArray = [];

  buildingUUIDArray.forEach((buildingUUID) => {
    assetMarkers.forEach((asset) => {
      const { id } = asset.location.building_level.possible_buildings[0];

      if (id === buildingUUID) {
        filteredMarkersArray.push(asset);
      }
    });
  });

  return filteredMarkersArray.length;
}
export function getUserAssetCountFromFeatureBuildingUUIDArray(
  buildingUUIDArray: string[],
  users: UserType[],
) {
  let filteredMarkersArray = [];

  buildingUUIDArray.forEach((buildingUUID) => {
    users.forEach((user) => {
      const buildingIDArray = user.location.building_level.possible_buildings.map(
        (building) => building.id,
      );

      if (buildingIDArray.includes(buildingUUID)) {
        filteredMarkersArray.push(user);
      }
    });
  });

  return filteredMarkersArray.length;
}

export function getCoordsFromAssets(assets: EstimateType[]) {
  let coords: any = [];

  assets.forEach((asset) => {
    const latLongArray = [
      asset.location.coordinates.latitude,
      asset.location.coordinates.longitude,
    ];

    const correctedCoord: any = [...latLongArray];

    coords.push(correctedCoord);
  });

  return coords;
}
export function getCoordsFromAssetUserEstimates(estimates: EstimateType[] | UserType[]) {
  let coords: any = [];

  estimates.forEach((asset) => {
    const latLongArray = [
      asset.location.coordinates.latitude,
      asset.location.coordinates.longitude,
    ];

    const correctedCoord: any = [...latLongArray];

    coords.push(correctedCoord);
  });

  return coords;
}

export function getCoordsFromUsers(users: UserType[]) {
  let coords: any = [];

  users.forEach((user) => {
    const latLongArray = [user.location.coordinates.latitude, user.location.coordinates.longitude];

    coords.push(latLongArray);
  });

  return coords;
}

export function getArrayOfValuesFromOptions(options: any) {
  let arr: any = [];

  options.forEach((element: any) => {
    arr.push(element.value);
  });
  return arr;
}

export function getArrayOfValuesFromAssetStatusOptions(optionsArr: AssetStatesObjectType[]) {
  let arr: any = [];

  optionsArr.forEach((element: AssetStatesObjectType) => {
    arr.push(element.asset_status_id);
  });
  arr.push('null');

  return arr;
}

// A function that returns only items if the assets from the APIs owner & subtype exists within the selected filter params.
// used for returning intersection of results of owner & subtype for filter dropdowns.
export function getIntersectionFilteredAssets(
  assets: EstimateType[],
  assetOwnerQueryArray: string[],
  assetSubtypeQueryArray: string[],
  assetStatusQueryArray: string[],
) {
  let filteredAssetArray: EstimateType[] = [];

  // loop through assets with estimates,
  // and push assets only if subtype, owner and status exist on asset.
  assets.forEach((asset) => {
    const assetMatchesSelectedOwners = assetOwnerQueryArray.includes(asset.owner.id);
    const assetMatchesSelectedSubtype = assetSubtypeQueryArray.includes(asset.asset_type.id);
    let assetMatchesSelectedStatus = true;

    // only enter if statement, if asset status params are provided. Some deployments may have this feature disabled.
    if (assetStatusQueryArray.length > 0) {
      // asset status is slightly different as it has the potential to be null or undefined.
      // we need to check for that explicitely as a string if the status is not provided
      assetMatchesSelectedStatus = asset.status_assignment?.asset_status
        ? assetStatusQueryArray.includes(asset.status_assignment?.asset_status?.asset_status_id)
        : assetStatusQueryArray.includes('null');
    }

    if (assetMatchesSelectedOwners && assetMatchesSelectedSubtype && assetMatchesSelectedStatus) {
      filteredAssetArray.push(asset);
    }
  });
  return filteredAssetArray;
}

export function getReactSelectOptionsFromAssetStatusData(
  assetStatusArray: AssetStatesObjectType[],
): ReactSelectType[] {
  // a function that converts venueList objects into datastructure that react-select can use.
  const dataStructure: any[] = [];
  // add no status option first.
  dataStructure.push({
    label: 'No status set',
    value: 'null',
  });
  assetStatusArray.forEach((statusObj: AssetStatesObjectType) => {
    const newObj = {
      label: statusObj.description,
      value: statusObj.asset_status_id,
    };
    dataStructure.push(newObj);
  });

  return dataStructure;
}

export function getReactSelectOptionsFromVenueListData(
  venueList: VenueListType[],
): ReactSelectType[] {
  // a function that converts venueList objects into datastructure that react-select can use.
  const dataStructure: any[] = [];

  venueList.forEach((venue: any) => {
    const newObj = {
      label: venue.name,
      value: venue.venue_id,
    };
    dataStructure.push(newObj);
  });

  return dataStructure;
}

export function getVenueObjFromReactSelectValue(
  reactSelectValue: string,
  venuesList: VenueListType[],
): VenueListType {
  // a function that finds and returns matched venueObj from reactSelect value.
  return venuesList.filter((venue: VenueListType) => venue.venue_id === reactSelectValue)[0];
}

export function getAssetEstimatesOfSelectedBuilding(assets: EstimateType[], buildingUUID: string) {
  // simple function to return assets with building_uuid to match selected buildingsUUID
  return assets.filter((asset: EstimateType) => {
    return asset.location.building_level.possible_buildings[0].id === buildingUUID;
  });
}

export function getUserEstimatesOfSelectedBuilding(users: UserType[], buildingUUID: string) {
  // simple function to return users with possible building.ids to match selected buildingsUUID
  return users.filter((user: UserType) => {
    const buildingIDArray = user.location.building_level.possible_buildings.map(
      (building) => building.id,
    );

    return buildingIDArray.includes(buildingUUID);
  });
}

export function checkBuildingUUIDExistsWithinBuildingFeatures(
  buildingFeatures: any,
  buildingID: string,
) {
  const matchingId = buildingFeatures.filter((feature: any) => feature.id === buildingID);

  return matchingId.length > 0;
}

export function checkLevelIDExistsWithinLevelFeatures(levelFeatures: any, levelID: string) {
  const matchingId = levelFeatures.filter((feature: any) => {
    return feature.id === levelID;
  });

  return matchingId.length > 0;
}

export function checkEstimateLocationIsDifferentToRef(
  firstAssetEstimate: EstimateType | UserType,
  secondAssetEstimateRef: any,
): boolean {
  // serialise the locations, and checks if they are equal and returns boolean.
  if (!secondAssetEstimateRef.current) return true;
  const seccondAssetEstimate: EstimateType = secondAssetEstimateRef.current;

  const firstCoordsStr = JSON.stringify(firstAssetEstimate.location.coordinates);
  const secondCoordsStr = JSON.stringify(seccondAssetEstimate.location.coordinates);

  return firstCoordsStr !== secondCoordsStr;
}

export function checkEstimateIsInSameBuilding(
  asset: EstimateType | UserType,
  buildingSelectedID: string,
) {
  if (asset.location.is_outdoors) return false;
  return asset.location.building_level.possible_buildings[0].id === buildingSelectedID;
}

export function getRandomKey() {
  // create random key to force re-render of markers on every render.
  return Math.random() * 100;
}

export function checkEstimatesShouldDisplayConditions(...args: [boolean, boolean][]): boolean {
  // function that accepts dynamic array of array arguements pair
  // loop over each array pair, and check condition is what is expected to pass.
  // function will return false if one of these conditions does not match.
  return args.every((argArrayPair) => {
    const param = argArrayPair[0];
    const conditionToPass = argArrayPair[1];

    return param === conditionToPass;
  });
}

export function getAPIMatchedAssetFromBeaconID(assets: EstimateType[], beaconID: string) {
  const foundAsset = assets.filter((asset) => asset.assigned_beacon!.id === beaconID)[0];

  return foundAsset !== undefined ? foundAsset : null;
}

export function getSearchParamValue(searchParam: string) {
  // eslint-disable-next-line no-restricted-globals
  return new URLSearchParams(location.search).get(searchParam);
}

export function checkIfMobileFiltersHaveChangedSinceLastSearch(
  assetOwnerCheckedListArray: string[],
  assetSubtypeCheckedListArray: string[],
  assetOwnerCheckedListArrayRef: string[],
  assetSubtypeCheckedListArrayRef: string[],
) {
  const ownerArrayIsSame =
    JSON.stringify(assetOwnerCheckedListArray) === JSON.stringify(assetOwnerCheckedListArrayRef);
  const subtyypeArrayIsSame =
    JSON.stringify(assetSubtypeCheckedListArray) ===
    JSON.stringify(assetSubtypeCheckedListArrayRef);

  return !ownerArrayIsSame || !subtyypeArrayIsSame;
}

export function checkIfSearchTermHasChangedSinceLastSearch(
  searchTerm: string,
  searchTermRef: string,
) {
  return searchTerm !== searchTermRef;
}

export function checkIfAssetUserCheckboxesHaveChangedSinceLastSearch(
  assetUserCheckboxArray: any,
  assetUserCheckboxArrayRef: any,
) {
  return assetUserCheckboxArray !== assetUserCheckboxArrayRef;
}

export function getCombinedLevelAssetUserBounds(
  assets: EstimateType[],
  users: UserType[],
  levels: any,
) {
  // function to return bounds for map that includes all levels and markers.
  // To account for outside marked building assets too
  const bounds = L.geoJSON(levels).getBounds();
  const assetCoords: any = getCoordsFromAssets(assets);
  const userCoords: any = getCoordsFromUsers(users);
  const combinedCoords = [...assetCoords, ...userCoords];

  bounds.extend(combinedCoords);

  return bounds;
}

export function checkEstimatesAreOfSameBuilding(estimates: EstimateType[] | UserType[]): boolean {
  // A function to check building ID is the same for all estimates passed in param.
  const firstIndexBuildingId = estimates[0].location.building_level.possible_buildings[0].id;

  const buildingIDParamMatchesFirstIndex = (buildingID: string) =>
    buildingID === firstIndexBuildingId;

  return estimates.every((estimate) =>
    buildingIDParamMatchesFirstIndex(estimate.location.building_level.possible_buildings[0].id),
  );
}
