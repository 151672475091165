import React, { useEffect, useContext, useRef, useState } from 'react';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledMapDropdownControlWrapper } from '../../Styled';
import { Checkbox } from 'antd';
import DropdownControlReadout from '../SearchFilterBar/DropdownControlReadout';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';

import { AssetStatesContext } from 'contexts/assetStates.context';
import {
  getArrayOfValuesFromAssetStatusOptions,
  getReactSelectOptionsFromAssetStatusData,
} from 'views/MapPage/utils/utils';

type AssetStatusDropdownFilterInputType = {
  resetSearchFromCheckbox: () => void;
};
export default function MobileAssetStatusDropdownFilterInput({
  resetSearchFromCheckbox,
}: AssetStatusDropdownFilterInputType) {
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const componentKeyRef = useRef(Math.random() * 100); // create random number ref.
  const { apiCallInProgress } = useContext(MapControlContext);
  const {
    inSearchMode,
    assetStatusCheckedListArray,
    setAssetStatusCheckedListArray,
    setAssetStatusCheckAll,
    setAssetStatusFilterModified,
    setInFilterMode,
    assetStatusCheckAll,
    inFilterMode,
  } = useContext(SearchFilterContext);

  const { availableAssetStates } = useContext(AssetStatesContext);
  const defaultAssetStatusCheckedListArray =
    getArrayOfValuesFromAssetStatusOptions(availableAssetStates);
  const assetStatusOptions = getReactSelectOptionsFromAssetStatusData(availableAssetStates);

  const handleCheckAllChange = (e: any) => {
    setAssetStatusCheckedListArray(e.target.checked ? defaultAssetStatusCheckedListArray : ['nop']);
    setAssetStatusCheckAll(e.target.checked);
    resetSearchFromCheckbox();
    setAssetStatusFilterModified(!e.target.checked);
  };

  const handleCheckboxClick = (list: any) => {
    setAssetStatusCheckedListArray(list.length === 0 ? ['nop'] : list);
    setAssetStatusCheckAll(list.length === defaultAssetStatusCheckedListArray.length);
    resetSearchFromCheckbox();
    setAssetStatusFilterModified(list.length !== defaultAssetStatusCheckedListArray.length);
  };

  useEffect(() => {
    //reset checked to all, after performing a search
    if (inSearchMode) {
      setAssetStatusCheckedListArray(defaultAssetStatusCheckedListArray);
      setAssetStatusFilterModified(false);
      setAssetStatusCheckAll(true);
      setStatusDropdownOpen(false);
      setInFilterMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSearchMode]);

  useEffect(() => {
    // set checked list to ALL, after load and resetting filters
    if (!inFilterMode) {
      setAssetStatusCheckedListArray(defaultAssetStatusCheckedListArray);
      setAssetStatusFilterModified(false);
      setAssetStatusCheckAll(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // create new ref from random number after search occurs, and if checklist changes, Forcing input to re-render
    if (apiCallInProgress) {
      componentKeyRef.current = Math.random() * 100;
    }
  }, [apiCallInProgress, assetStatusCheckedListArray]);

  return assetStatusOptions.length > 0 ? (
    <SelectInput
      key={componentKeyRef.current}
      options={assetStatusOptions}
      placeholder={false}
      openMenuOnClick
      menuIsOpen={statusDropdownOpen}
      isSearchable={false}
      blurInputOnSelect
      onMenuClose={() => {
        setStatusDropdownOpen(false);
      }}
      onMenuOpen={() => {
        setStatusDropdownOpen(true);
      }}
      onKeyDown={(ev) => {
        // prevent user inputting keys, as we are just using dropdown.
        ev.preventDefault();
      }}
      components={{
        Control: ({ children, ...rest }) => {
          return (
            <StyledMapDropdownControlWrapper style={{ marginTop: '10px' }}>
              <components.Control {...rest}>
                <div style={{ display: 'flex' }}>
                  <span className="react-control__preplaceholder">Status: </span>
                  <DropdownControlReadout
                    type="status"
                    checkAll={assetStatusCheckAll}
                    checkedList={assetStatusCheckedListArray}
                  />
                </div>

                {children}
              </components.Control>
            </StyledMapDropdownControlWrapper>
          );
        },
        DropdownIndicator: ({ ...rest }) => (
          <button style={{ transform: `rotate(${statusDropdownOpen ? '180deg' : '0deg'}` }}>
            <components.DropdownIndicator {...rest} />
          </button>
        ),
        Menu: ({ ...rest }) => {
          return (
            <components.Menu {...rest}>
              <Checkbox
                disabled={apiCallInProgress}
                style={{ marginBottom: '20px', width: '100%' }}
                checked={assetStatusCheckAll}
                onChange={handleCheckAllChange}
              >
                All
              </Checkbox>

              <Checkbox.Group
                disabled={apiCallInProgress}
                value={assetStatusCheckedListArray}
                options={assetStatusOptions}
                defaultValue={defaultAssetStatusCheckedListArray}
                onChange={handleCheckboxClick}
              />
            </components.Menu>
          );
        },
      }}
    />
  ) : null;
}
