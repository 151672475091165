import { useContext, useEffect, useRef, useState } from 'react';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useCallSearchAssets from './useCallSearchAssets';
import {
  checkIfAssetUserCheckboxesHaveChangedSinceLastSearch,
  checkIfMobileFiltersHaveChangedSinceLastSearch,
  checkIfSearchTermHasChangedSinceLastSearch,
  getArrayOfValuesFromOptions,
} from '../utils/utils';
import { options } from '../components/mobile/MobileAssetsPeopleCheckBoxGroup';

// a hook to contain filtering functions used for mobile nav panel for map.
export default function useMobileFilterPanel({
  setMobileMapFilterMenuOpen,
}: {
  setMobileMapFilterMenuOpen: (arg: boolean) => void;
}) {
  const defaultOptions = getArrayOfValuesFromOptions(options);
  const [noFilterResultsReturned, setNoFilterResultsReturned] = useState(false);
  const [assetUserCheckboxArray, setAssetUserCheckboxArray] = useState<any>(defaultOptions);
  const [noSearchResultsReturned, setNoSearchResultsReturned] = useState(false);

  const {
    searchTerm,
    setSearchTerm,
    setDoNotCalculateFloor,
    setInSearchMode,
    setInFilterMode,
    inSearchMode,
    inFilterMode,
    setAssetOwnerCheckedListArray,
    setAssetSubtypeCheckedListArray,
    setAssetSubtypeCheckAll,
    setAssetOwnerCheckAll,
    assetOwnerFilterModified,
    assetSubtypeFilterModified,
    assetStatusFilterModified,
    assetOwnerCheckedListArray,
    assetSubtypeCheckedListArray,
    assetStatusCheckedListArray,
    defaultAssetOwnerCheckedListArray,
    defaultAssetSubtypeCheckedListArray,
    defaultAssetStatusCheckedListArray,
  } = useContext(SearchFilterContext);

  const { setRecallGetAssets, setRecallGetUsers, setShowAssetsChecked, setShowUsersChecked } =
    useContext(MapControlContext);
  const { callSearchAssets, callFilterAssets } = useCallSearchAssets();
  const searchTermRef: any = useRef('');
  const assetOwnerCheckedListArrayRef: any = useRef(assetOwnerCheckedListArray);
  const assetSubtypeCheckedListArrayRef: any = useRef(assetSubtypeCheckedListArray);
  const assetStatusCheckedListArrayRef: any = useRef(assetStatusCheckedListArray);

  const assetUserCheckboxArrayRef: any = useRef(assetUserCheckboxArray);

  function resetSearchFromCheckbox() {
    setSearchTerm('');
    setNoSearchResultsReturned(false);
  }

  function handleMobileSearchFilters() {
    const filtersModified =
      assetOwnerFilterModified || assetSubtypeFilterModified || assetStatusFilterModified;

    const hasSearchTerm = searchTerm.length > 0;
    const assetsChecked = assetUserCheckboxArray.includes('assets');
    const usersChecked = assetUserCheckboxArray.includes('people');

    if (hasSearchTerm) {
      callSearchAssets(searchTerm).then((items) => {
        const noResults = items === undefined || items.length < 1;

        setNoSearchResultsReturned(noResults);
        setInSearchMode(!noResults); // only is set if there are results.
        setInFilterMode(false);
        searchTermRef.current = searchTerm;
        assetOwnerCheckedListArrayRef.current = defaultAssetOwnerCheckedListArray;
        assetSubtypeCheckedListArrayRef.current = defaultAssetSubtypeCheckedListArray;
        assetStatusCheckedListArrayRef.current = defaultAssetStatusCheckedListArray;
        setAssetOwnerCheckAll(true);
        setAssetSubtypeCheckAll(true);

        // do not close menu if there are no results from search.
        if (!noResults) {
          setMobileMapFilterMenuOpen(false);
        }
      });
    } else if (filtersModified) {
      callFilterAssets(
        null,
        assetOwnerCheckedListArray,
        assetSubtypeCheckedListArray,
        assetStatusCheckedListArray,
      ).then((filteredItems) => {
        const noResults = filteredItems === undefined || filteredItems.length < 1;
        setNoFilterResultsReturned(noResults);

        setInFilterMode(!noResults); // only is set if there are results
        setInSearchMode(false);
        // store checkedlists in refs after search.
        assetOwnerCheckedListArrayRef.current = assetOwnerCheckedListArray;
        assetSubtypeCheckedListArrayRef.current = assetSubtypeCheckedListArray;
        assetStatusCheckedListArrayRef.current = defaultAssetStatusCheckedListArray;
        searchTermRef.current = ''; // reset search after filter takes place.

        // do not close menu if there are no results from search.
        if (!noResults) {
          setMobileMapFilterMenuOpen(false);
        }
      });
    } else {
      // close menu and recall assets if no searchterm or filters exist
      setMobileMapFilterMenuOpen(false);
      setInSearchMode(false);
      setInFilterMode(false);
      setRecallGetAssets(true);
      setRecallGetUsers(true);
      setDoNotCalculateFloor(true); // set to true so we dont recenter map / floor
      setNoFilterResultsReturned(false);
      setNoSearchResultsReturned(false);
    }
    assetUserCheckboxArrayRef.current = assetUserCheckboxArray;

    setNoSearchResultsReturned(false);
    setNoFilterResultsReturned(false);
    setShowAssetsChecked(assetsChecked);
    setShowUsersChecked(usersChecked);
  }

  function handleClosePanel() {
    const filtersShouldRevertToRefs = checkIfMobileFiltersHaveChangedSinceLastSearch(
      assetOwnerCheckedListArray,
      assetSubtypeCheckedListArray,
      assetOwnerCheckedListArrayRef.current,
      assetSubtypeCheckedListArrayRef.current,
    );
    const assetUserCheckboxesShouldRevert = checkIfAssetUserCheckboxesHaveChangedSinceLastSearch(
      assetUserCheckboxArray,
      assetUserCheckboxArrayRef.current,
    );
    // do not reset search, if search or filter mode is active.
    if (inFilterMode) {
      setMobileMapFilterMenuOpen(false);
      if (searchTerm.length > 0) {
        setSearchTerm('');
      }
    } else if (inSearchMode) {
      setMobileMapFilterMenuOpen(false);
    } else {
      // full reset
      setMobileMapFilterMenuOpen(false);
      setInSearchMode(false);
      setInFilterMode(false);
      setRecallGetAssets(true);
      setRecallGetUsers(true);
      setDoNotCalculateFloor(true); // set to true so we dont recenter map / floor
      setNoFilterResultsReturned(false);
      setNoSearchResultsReturned(false);
      setSearchTerm('');
      searchTermRef.current = '';
    }

    // revert filters to last search values, if user has modified, not searched and closes menu.
    if (filtersShouldRevertToRefs) {
      setAssetOwnerCheckedListArray(assetOwnerCheckedListArrayRef.current);
      setAssetSubtypeCheckedListArray(assetSubtypeCheckedListArrayRef.current);

      setAssetSubtypeCheckAll(
        assetSubtypeCheckedListArrayRef.current.length ===
          defaultAssetSubtypeCheckedListArray.length,
      );
    }
    setAssetOwnerCheckAll(
      assetOwnerCheckedListArrayRef.current.length === defaultAssetOwnerCheckedListArray.length,
    );
    if (checkIfSearchTermHasChangedSinceLastSearch(searchTerm, searchTermRef.current)) {
      setSearchTerm(searchTermRef.current);
    }

    if (assetUserCheckboxesShouldRevert) {
      setAssetUserCheckboxArray(assetUserCheckboxArrayRef.current);
    }
  }

  useEffect(() => {
    // reset no search panel when checkboxes change.
    setNoFilterResultsReturned(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetOwnerCheckedListArray, assetSubtypeCheckedListArray]);

  useEffect(() => {
    // reset search when searchterm changes.
    setNoSearchResultsReturned(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    // set refs to default on load.
    assetOwnerCheckedListArrayRef.current = defaultAssetOwnerCheckedListArray;
    assetSubtypeCheckedListArrayRef.current = defaultAssetSubtypeCheckedListArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    resetSearchFromCheckbox,
    handleMobileSearchFilters,
    handleClosePanel,
    setNoFilterResultsReturned,
    setAssetUserCheckboxArray,
    noSearchResultsReturned,
    noFilterResultsReturned,
    setNoSearchResultsReturned,
    assetUserCheckboxArray,
  };
}
