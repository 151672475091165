import React, { useContext, useEffect, useRef } from 'react';
import { AssetsContext } from '../contexts/assets.context';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { UsersContext } from '../contexts/users.context';
import BuildingsGeoJsonLayer from '../layers/BuildingsGeoJsonLayer';
import SelectedBuildingAssetMarkersLayer from '../layers/SelectedBuildingAssetMarkersLayer';
import SelectedBuildingUsersMarkersLayer from '../layers/SelectedBuildingUsersMarkersLayer';
import UnitGeoJsonLayer from '../layers/UnitGeoJsonLayer';
import { filterAssetMarkersByFloorUUID, filterUsersMarkersByFloorUUID } from '../utils/utils';
import PollingIntervalComponent from '../components/PollingIntervalComponent';
import { PERMISSIONS } from 'config/permissions';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import useFloorSelectionEffects from '../hooks/useFloorSelectionEffects';
import useFilteredAssets from '../hooks/useFilteredAssets';
import useURLParamEffects from '../hooks/useURLParamEffects';
import OutdoorAssetMarkersLayer from '../layers/OutdoorAssetMarkersLayer';
import useMapHooks from '../hooks/useMapHooks';
import OutsideMappedBuildingUserMarkersLayer from '../layers/OutsideMappedBuildingUserMarkersLayer';
import useFilteredUsers from '../hooks/useFilteredUsers';
import useURLSearchParams from 'hooks/useURLSearchParams.hook';

// a generalist controller component for displaying the several layers onto the map.
// also handles the division of markers to the floor level to be passed in if the buildingSelected layer is showing a specific floor.
function LayersController() {
  const {
    floorSelectedIDArray,
    buildingIsSelected,
    apiCallInProgress,
    assetsApiCallInProgress,
    usersApiCallInProgress,
  } = useContext(MapControlContext);

  const { rawIndoorAssets, rawOutsideMappedBuildingsAssets } = useContext(AssetsContext);
  const { usersMarkers, usersOutsideMappedBuildingsMarkers } = useContext(UsersContext);
  const { panMapToCombinedLevelsAssetUserBounds } = useMapHooks();

  const getVisibleAssets = useFilteredAssets();
  const getVisibleUsers = useFilteredUsers();
  const postLoadHasRun = useRef(false);

  const { checkHandleMatchedVenueFromMobileParams } = useURLParamEffects();
  const { removeAllSearchParamsFromURL } = useURLSearchParams();

  useFloorSelectionEffects(); // invoke hook.

  useEffect(() => {
    // post map load functions
    if (
      !postLoadHasRun.current &&
      !assetsApiCallInProgress &&
      !usersApiCallInProgress &&
      !apiCallInProgress
    ) {
      checkHandleMatchedVenueFromMobileParams();
      panMapToCombinedLevelsAssetUserBounds();
      postLoadHasRun.current = true;

      setTimeout(() => {
        // timeout to ensure params aren't removed before needed.
        removeAllSearchParamsFromURL();
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsApiCallInProgress, usersApiCallInProgress, apiCallInProgress]);

  return (
    <>
      {!apiCallInProgress && <PollingIntervalComponent />}

      {buildingIsSelected && (
        <ComponentFeaturePermissionRenderWrapper
          featurePermissions={PERMISSIONS.FEATURE.ASSET_TRACKING}
        >
          <SelectedBuildingAssetMarkersLayer
            assetMarkers={filterAssetMarkersByFloorUUID(
              getVisibleAssets(rawIndoorAssets),
              floorSelectedIDArray,
            )}
          />
        </ComponentFeaturePermissionRenderWrapper>
      )}
      {buildingIsSelected && <UnitGeoJsonLayer />}
      <OutdoorAssetMarkersLayer assetMarkers={getVisibleAssets(rawOutsideMappedBuildingsAssets)} />
      {buildingIsSelected && (
        <ComponentFeaturePermissionRenderWrapper
          featurePermissions={PERMISSIONS.FEATURE.USER_TRACKING}
        >
          <SelectedBuildingUsersMarkersLayer
            usersMarkers={filterUsersMarkersByFloorUUID(
              getVisibleUsers(usersMarkers),
              floorSelectedIDArray,
            )}
          />
        </ComponentFeaturePermissionRenderWrapper>
      )}

      <OutsideMappedBuildingUserMarkersLayer
        userMarkers={getVisibleUsers(usersOutsideMappedBuildingsMarkers)}
      />

      <BuildingsGeoJsonLayer />
    </>
  );
}

export default LayersController;
