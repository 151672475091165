import { useRef } from 'react';

export default function useAbortControllerRef() {
  // hook that creates abort controller attached to ref, usable between components.
  const abortControllerRef: any = useRef();

  abortControllerRef.current = new AbortController();

  return { abortControllerRef };
}
